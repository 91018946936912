<template>
  <div class="membership-table">
    <!-- header -->
    <div class="membership-table__header">
      membresías activas
    </div>
    <!--  First row  -->
    <div class="color-1" />
    <div class="color-1">Mensuales</div>
    <div class="color-1">Anuales</div>
    <div class="total color-2">Totales</div>
    <!-- Second row -->
    <div class="color-3 question-container">
      <InfoIcon
        :messageId="1"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Al {{ pastCatalogDateInfo.day }} de {{ pastCatalogDateInfo.monthName }}:</div>
    </div>
    <div class="amount color-3">{{ pastCatalogAmounts.monthly }}</div>
    <div class="amount color-3">{{ pastCatalogAmounts.yearly }}</div>
    <div class="total amount color-4">{{ pastCatalogAmounts.total }}</div>
    <!-- Third row -->
    <div class="question-container color-7" @click="openMenu1">
      <img v-if="!hidden1" src="/images/arrow_up3pv2.png" />
      <img v-if="hidden1" src="/images/arrow_down3pv2.png" />
      <div>Compras este mes:</div>
    </div>
    <div class="amount color-7" @click="openMenu1">{{ shoppingThisMonth.monthly }}</div>
    <div class="amount color-7" @click="openMenu1">{{ shoppingThisMonth.yearly }}</div>
    <div class="total amount color-8" @click="openMenu1">{{ shoppingThisMonth.total }}</div>
    <div class="question-container color-5" v-if="hidden1">
      <InfoIcon
        :messageId="2"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Amateur:</div>
    </div>
    <div class="amount color-5" v-if="hidden1">-</div>
    <div class="amount color-5" v-if="hidden1">-</div>
    <div class="total amount color-6" v-if="hidden1">-</div>
    <div class="question-container color-5" v-if="hidden1">
      <InfoIcon
        :messageId="3"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Ídolo:</div>
    </div>
    <div class="amount color-5" v-if="hidden1">-</div>
    <div class="amount color-5" v-if="hidden1">-</div>
    <div class="total amount color-6" v-if="hidden1">-</div>
    <div class="question-container color-5" v-if="hidden1">
      <InfoIcon
        :messageId="4"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Leyenda:</div>
    </div>
    <div class="amount color-5" v-if="hidden1">-</div>
    <div class="amount color-5" v-if="hidden1">-</div>
    <div class="total amount color-6" v-if="hidden1">-</div>
    <!-- fourth row -->
    <div class="question-container color-7" @click="openMenu2">
      <img v-if="!hidden2" src="/images/arrow_up3pv2.png" />
      <img v-if="hidden2" src="/images/arrow_down3pv2.png" />
      <div>Renovaciones este mes:</div>
    </div>
    <div class="amount color-7" @click="openMenu2">{{ renewalsThisMonth.monthly }}</div>
    <div class="amount color-7" @click="openMenu2">{{ renewalsThisMonth.yearly }}</div>
    <div class="total amount color-8" @click="openMenu2">{{ renewalsThisMonth.total }}</div>
    <div class="question-container color-5" v-if="hidden2">
      <InfoIcon
        :messageId="11"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Amateur:</div>
    </div>
    <div class="amount color-5" v-if="hidden2">-</div>
    <div class="amount color-5" v-if="hidden2">-</div>
    <div class="total amount color-6" v-if="hidden2">-</div>
    <div class="question-container color-5" v-if="hidden2">
      <InfoIcon
        :messageId="12"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Ídolo:</div>
    </div>
    <div class="amount color-5" v-if="hidden2">-</div>
    <div class="amount color-5" v-if="hidden2">-</div>
    <div class="total amount color-6" v-if="hidden2">-</div>
    <div class="question-container color-5" v-if="hidden2">
      <InfoIcon
        :messageId="13"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Leyenda:</div>
    </div>
    <div class="amount color-5" v-if="hidden2">-</div>
    <div class="amount color-5" v-if="hidden2">-</div>
    <div class="total amount color-6" v-if="hidden2">-</div>
    <!-- fifth row -->
    <div class="question-container  color-7">
      <InfoIcon
        :messageId="4"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Membresías confirmadas este mes:</div>
    </div>
    <div class="amount indicator-container color-7">
      <div>
        {{ lastCatalogAmounts.monthly }}
        <div class="indicator monthly" />
      </div>
    </div>
    <div class="amount indicator-container color-7">
      <div>
        {{ lastCatalogAmounts.yearly }}
        <div class="indicator yearly" />
      </div>
    </div>
    <div class="amount indicator-container total color-8">{{ lastCatalogAmounts.total }}</div>
    <!-- sixth row  -->
    <div class="question-container color-9">
      <InfoIcon
        :messageId="5"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Membresías por renovar este mes:</div>
    </div>
    <div class="amount color-9">{{ membershipsToRenew.monthly }}</div>
    <div class="amount color-9">{{ membershipsToRenew.yearly }}</div>
    <div class="total amount color-10">{{ membershipsToRenew.total }}</div>
    <!-- new row  -->
    <div class="question-container membership-table__bottom-left color-11">
      <InfoIcon
        :messageId="14"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Cancelaciones este mes:</div>
    </div>
    <div class="amount color-11">-</div>
    <div class="amount color-11">-</div>
    <div class="total amount membership-table__bottom-right color-11 indicator-container">
      -
    </div>
    <!-- new row  -->
    <!-- seventh row  -->
    <div class="question-container membership-table__bottom-left color-3">
      <InfoIcon
        :messageId="6"
        @mouse-click="onQuestionClick"
        @mouse-over="onQuestionMouseOver"
        @mouse-leave="onQuestionMouseLeave"
      />
      <div>Membresías activas:</div>
    </div>
    <div class="amount color-3">{{ lastCatalogAmounts.monthly + membershipsToRenew.monthly }}</div>
    <div class="amount color-3">{{ lastCatalogAmounts.yearly + membershipsToRenew.yearly }}</div>
    <div class="total amount membership-table__bottom-right color-4 indicator-container">
      <div>
        {{ lastCatalogAmounts.total + membershipsToRenew.total }}
        <div class="indicator total" />
      </div>
    </div>
  </div>
</template>

<script>
import { getMonthNameByDate, subtractMonths } from '@/utils/date-and-time';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { getAmountsMembershipsToRenew, getCatalogAmounts } from '@/utils/3pv';
import InfoIcon from '@/components/Sections/3pv/InfoIcon';

const shoppingThisMonthvariablesToAdd = {
  monthly: [{ name: 'Usuarios nuevos mensuales', variable_id: 8 }],
  yearly: [{ name: 'Usuarios nuevos anuales', variable_id: 9 }],
};
const renewalsThisMonthariablesToAdd = {
  monthly: [
    { name: 'Renovaciones mensuales', variable_id: 10 },
    { name: 'Reactivaciones mensuales', variable_id: 16 },
  ],
  yearly: [
    { name: 'Renovaciones anuales', variable_id: 11 },
    { name: 'Reactivaciones anuales', variable_id: 17 },
  ],
};

export default {
  name: 'MembershipTable',
  components: {
    InfoIcon,
  },
  props: {
    catalogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pastCatalogDateInfo() {
      const dateInfo = {
        day: 0,
        monthName: 'N/A',
      };
      if (this.catalogs.length > 0) {
        const pastCatalogIndex = this.catalogs[1] ? 1 : 0;
        let pastCatalogEndDate = new Date(this.catalogs[pastCatalogIndex].end_date);
        if (pastCatalogIndex === 0) {
          pastCatalogEndDate = subtractMonths(pastCatalogEndDate, 1);
        }
        dateInfo.day =
          pastCatalogIndex === 1
            ? lastDayOfMonth(pastCatalogEndDate).getDate()
            : lastDayOfMonth(pastCatalogEndDate).getDate();
        dateInfo.monthName = getMonthNameByDate(pastCatalogEndDate);
      }
      return dateInfo;
    },
    membershipsToRenew() {
      let amounts = {
        monthly: 0,
        yearly: 0,
        total: 0,
      };
      const currentCatalogIndex = this.catalogs[0] ? 1 : 0;
      if (currentCatalogIndex === 0) {
        amounts.monthly = '-';
        amounts.yearly = '-';
        amounts.total = '-';
      } else {
        const currentCatalog = this.catalogs[0];
        const lastCatalogMonth = this.catalogs[1];
        const lastCatalogYear = this.catalogs[12];

        const monthlyAmount = getAmountsMembershipsToRenew(currentCatalog, lastCatalogMonth, 0, 'monthly');
        const yearlyAmount = getAmountsMembershipsToRenew(currentCatalog, lastCatalogYear, 0, 'yearly');
        amounts.monthly = monthlyAmount;
        amounts.yearly = yearlyAmount;
        amounts.total = monthlyAmount + yearlyAmount;
      }
      return amounts;
    },
    renewalsThisMonth() {
      let amounts = {
        monthly: 0,
        yearly: 0,
        total: 0,
      };
      const currentCatalogIndex = this.catalogs[0] ? 1 : 0;
      if (currentCatalogIndex === 0) {
        amounts.monthly = '-';
        amounts.yearly = '-';
        amounts.total = '-';
      } else {
        const currentCatalog = this.catalogs[0];
        const monthlyAmount = this.getAmountsRenewalsThisMonth(currentCatalog, 0, 'monthly');
        const yearlyAmount = this.getAmountsRenewalsThisMonth(currentCatalog, 0, 'yearly');
        amounts.monthly = monthlyAmount;
        amounts.yearly = yearlyAmount;
        amounts.total = monthlyAmount + yearlyAmount;
      }
      return amounts;
    },
    shoppingThisMonth() {
      let amounts = {
        monthly: 0,
        yearly: 0,
        total: 0,
      };
      const currentCatalogIndex = this.catalogs[0] ? 1 : 0;
      if (currentCatalogIndex === 0) {
        amounts.monthly = '-';
        amounts.yearly = '-';
        amounts.total = '-';
      } else {
        const currentCatalog = this.catalogs[0];
        const monthlyAmount = this.getAmountsShoppingThisMonth(currentCatalog, 0, 'monthly');
        const yearlyAmount = this.getAmountsShoppingThisMonth(currentCatalog, 0, 'yearly');
        amounts.monthly = monthlyAmount;
        amounts.yearly = yearlyAmount;
        amounts.total = monthlyAmount + yearlyAmount;
      }
      return amounts;
    },
    pastCatalogAmounts() {
      let amounts = {
        monthly: 0,
        yearly: 0,
        total: 0,
      };
      const pastCatalogIndex = this.catalogs[1] ? 1 : 0;
      if (pastCatalogIndex === 0) {
        amounts.monthly = '-';
        amounts.yearly = '-';
        amounts.total = '-';
      } else {
        const pastCatalog = this.catalogs[1];
        const monthlyAmount = getCatalogAmounts(pastCatalog, 0, 'monthly');
        const yearlyAmount = getCatalogAmounts(pastCatalog, 0, 'yearly');
        amounts.monthly = monthlyAmount;
        amounts.yearly = yearlyAmount;
        amounts.total = monthlyAmount + yearlyAmount;
      }
      return amounts;
    },
    lastCatalogAmounts() {
      let amounts = {
        monthly: 0,
        yearly: 0,
        total: 0,
      };

      const monthlyAmount = getCatalogAmounts(this.catalogs[0], 0, 'monthly');
      const yearlyAmount = getCatalogAmounts(this.catalogs[0], 0, 'yearly');
      amounts.monthly = monthlyAmount;
      amounts.yearly = yearlyAmount;
      amounts.total = monthlyAmount + yearlyAmount;

      return amounts;
    },
  },
  data: function() {
    return {
      hidden1: false,
      hidden2: false,
    };
  },
  methods: {
    openMenu2() {
      this.hidden2 = !this.hidden2;
    },
    openMenu1() {
      this.hidden1 = !this.hidden1;
    },
    onQuestionMouseOver($event) {
      this.$emit('question-mouse-over', $event);
    },
    onQuestionMouseLeave() {
      this.$emit('question-mouse-leave');
    },
    onQuestionClick($event) {
      this.$emit('question-click', $event);
    },
    getAmountsRenewalsThisMonth(catalog, Amount = 0, type) {
      if (!catalog) return Amount;
      let AmountAux = Amount;
      for (const variable of catalog.variables) {
        if (type == 'monthly') {
          const monthlyAdd = renewalsThisMonthariablesToAdd.monthly.find(
            variableToAdd => variableToAdd.variable_id === variable.variable_id,
          );
          if (monthlyAdd) {
            AmountAux += variable.amount;
          }
        } else {
          const yearlyAdd = renewalsThisMonthariablesToAdd.yearly.find(
            variableToAdd => variableToAdd.variable_id === variable.variable_id,
          );
          if (yearlyAdd) {
            AmountAux += variable.amount;
          }
        }
      }
      return AmountAux;
    },
    getAmountsShoppingThisMonth(catalog, Amount = 0, type) {
      if (!catalog) return Amount;
      let AmountAux = Amount;
      for (const variable of catalog.variables) {
        if (type == 'monthly') {
          const monthlyAdd = shoppingThisMonthvariablesToAdd.monthly.find(
            variableToAdd => variableToAdd.variable_id === variable.variable_id,
          );
          if (monthlyAdd) {
            AmountAux += variable.amount;
          }
        } else {
          const yearlyAdd = shoppingThisMonthvariablesToAdd.yearly.find(
            variableToAdd => variableToAdd.variable_id === variable.variable_id,
          );
          if (yearlyAdd) {
            AmountAux += variable.amount;
          }
        }
      }
      return AmountAux;
    },
  },
};
</script>

<style scoped lang="scss">
$borderRadius: 6px;
.color-1 {
  background-color: #ffffff !important;
}
.color-2 {
  background-color: #f8f8f8 !important;
}
.color-3 {
  background-color: #f3f3f3 !important;
}
.color-4 {
  background-color: #ececec !important;
}
.color-5 {
  background-color: #edf9dd !important;
}
.color-6 {
  background-color: #e6f2d6 !important;
}
.color-7 {
  background-color: #cbeaa2 !important;
}
.color-8 {
  background-color: #c5e39d !important;
}
.color-9 {
  background-color: #e7f6fd !important;
}
.color-10 {
  background-color: #e0eff6 !important;
}
.color-11 {
  background-color: #fdc7bf !important;
}

.membership-table {
  display: grid;
  grid-template-columns: 38% 1fr 1fr 1fr;
  margin: 0 1rem;
  font-family: Avenir-Demi, sans-serif;
  font-size: 0.8rem;
  color: #4c4c4c;

  & > div {
    padding: 0.3rem 0 0.2rem;

    @media screen and(max-width: 500px) {
      font-size: 0.6rem;
    }
  }

  & > div.membership-table__header {
    grid-column-start: 1;
    grid-column-end: 5;
    background-color: #6eb90f;
    text-transform: uppercase;
    color: white;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    font-size: 0.8rem;
  }

  & > div {
    border: solid 1px #c3c3c3;
  }

  & div.membership-table__date {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 1rem;
    font-size: 0.9rem;
    text-align: right;
    @media screen and(max-width: 500px) {
      font-size: 0.65rem;
    }
  }

  &__bottom-left {
    border-bottom-left-radius: $borderRadius;
  }

  &__bottom-right {
    border-bottom-right-radius: $borderRadius;
  }

  & .total {
    background-color: #f8f8f8;
  }

  & .amount {
    font-size: 1rem;
    font-family: Avenir-Pro-Bold, sans-serif;

    @media screen and(max-width: 500px) {
      font-size: 0.7rem;
    }
  }

  & .indicator-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div:first-child {
      position: relative;

      & > .indicator {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 0.35em;
        left: -1.5em;

        &.monthly {
          background-color: #12a6f1;
        }

        &.yearly {
          background-color: #6eb90f;
        }

        &.total {
          background-color: #4e62e8;
        }

        @media screen and(max-width: 500px) {
          width: 7px;
          height: 7px;
          top: 0.25em;
          left: -1em;
        }
      }
    }
  }
}

.question-container {
  display: grid;
  grid-template-columns: 22px 1fr;
  font-size: 0.9rem;
  padding: 0.4rem 0.5rem 0 !important;

  @media screen and(max-width: 500px) {
    font-size: 0.65rem;
  }

  & > div:last-child {
    justify-self: left;
    text-align: left;
    margin-left: 0.5rem;
    @media screen and(max-width: 380px) {
      margin-left: 0.2rem;
    }
  }
}
</style>

<template>
  <div>
    <!-- menu - header -->
    <div class="main3pv-menu">
      <img class="main3pv-menu__img" src="/images/logo-golstats.png" />
      <div class="main3pv-menu__cont-item-user">
        <div class="main3pv-menu__cont-item-user__name-user">{{ user.name }}</div>
        <div class="main3pv-menu__cont-item-user__circle-user" @click="openMenu">{{ abrevName }}</div>
        <div class="main3pv-menu__cont-menu" :class="mOpen ? 'show' : ''">
          <!-- <div class="main3pv-menu__cont-menu__item">
            <div><img class="main3pv-menu__cont-menu__item__icon" src="/images/icn-contact.png" /></div>
            <div><div class="main3pv-menu__cont-menu__item__text">Contáctanos</div></div>
          </div> -->
          <div class="main3pv-menu__cont-menu__item">
            <div><img class="main3pv-menu__cont-menu__item__icon" src="/images/icn-log-out.png" /></div>
            <div>
              <div class="main3pv-menu__cont-menu__item__text" @click.prevent.stop="onClickLogout">Cerrar Sesión</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- menu - header -->
    <!-- container -->
    <div class="main3pv-container">
      <div class="main3pv-container__panel">
        <div class="main3pv-container__panel__tabs">
          <div v-for="(section, index) in sections" :key="section.id" @click="onTabClicked(section)">
            <div v-if="index > 0" class="spacer">
              <div />
            </div>
            <div
              class="main3pv-container__panel__tabs__tab__title"
              :class="{ 'selected-tab': selectedTab.id === section.id }"
            >
              {{ section.name }}
            </div>
            <div class="indicator" :class="{ selected: selectedTab.id === section.id }" />
          </div>
        </div>
        <div class="main3pv-container__panel__content">
          <Component
            :is="panelComponent"
            :id="selectedTab.id"
            :catalogs="infoData.catalogs"
            :daily-commissions="
              infoData.aggregate_daily_detail_commissions && Array.isArray(infoData.aggregate_daily_detail_commissions)
                ? infoData.aggregate_daily_detail_commissions
                : []
            "
            @question-mouse-over="onQuestionMouseOver"
            @question-mouse-leave="onQuestionMouseLeave"
            @question-click="onQuestionClick"
          />
        </div>
      </div>
      <!--      <div class="main3pv-container__info-date-stats">
        <span class="main3pv-container__info-date-stats__bold">Estadísticas mes de Agosto</span> - Del 1-12 de Agosto
      </div>-->
      <div class="main3pv-container__container-graph">
        <div class="main3pv-container__container-graph__inner">
          <VueApexCharts
            width="100%"
            height="100%"
            type="line"
            :options="chartData.options"
            :series="chartData.series"
            :key="chartKey"
          />
        </div>
      </div>
      <div class="main3pv-container__container-total-until-today">
        <div class="main3pv-container__container-total-until-today__title">
          Total acumulado hasta el último mes:
        </div>
        <div class="main3pv-container__container-total-until-today__total">
          ${{ paidCommissions | toCurrencyString }}
          <span class="main3pv-container__container-total-until-today__total__text">MXN</span>
        </div>
      </div>
      <!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL -->
      <!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL -->
      <!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL -->
      <div class="main3pv-container__container-profit-month">
        <div class="main3pv-container__container-profit-month__title">Ingreso por recibir este mes:</div>
        <div class="main3pv-container__container-profit-month__container1">
          <div class="main3pv-container__container-profit-month__container1__section1">
            <div class="main3pv-container__container-profit-month__container1__section1__title">
              <div class="text-with-question-container">
                <div class="text">
                  Membresías mensuales
                  <div class="icon">
                    <InfoIcon
                      :messageId="7"
                      :isBlack="false"
                      @mouse-click="onQuestionClick"
                      @mouse-over="onQuestionMouseOver"
                      @mouse-leave="onQuestionMouseLeave"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__section1__item border-dotted">
              <div class="main3pv-container__container-profit-month__container1__section1__item__title">Amateur:</div>
              <div class="main3pv-container__container-profit-month__container1__section1__item__value">
                ${{ periodCommissions.monthly | toCurrencyString }}
                <span class="main3pv-container__container-profit-month__container1__section1__item__value__text"
                  >MXN</span
                >
              </div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__section1__item border-dotted">
              <div class="main3pv-container__container-profit-month__container1__section1__item__title">Ídolo:</div>
              <div class="main3pv-container__container-profit-month__container1__section1__item__value">
                ${{ periodCommissions.yearly | toCurrencyString }}
                <span class="main3pv-container__container-profit-month__container1__section1__item__value__text"
                  >MXN</span
                >
              </div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__section1__item ">
              <div class="main3pv-container__container-profit-month__container1__section1__item__title">Leyenda:</div>
              <div class="main3pv-container__container-profit-month__container1__section1__item__value">
                ${{ periodCommissions.yearly | toCurrencyString }}
                <span class="main3pv-container__container-profit-month__container1__section1__item__value__text"
                  >MXN</span
                >
              </div>
            </div>
          </div>
          <div class="main3pv-container__container-profit-month__container1__sep">
            <div class="main3pv-container__container-profit-month__container1__sep__border1">
              <div class="main3pv-container__container-profit-month__container1__sep__border1__sep"></div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__sep__border2"></div>
          </div>
          <div class="main3pv-container__container-profit-month__container1__section2">
            <div class="main3pv-container__container-profit-month__container1__section2__title">
              <div class="text-with-question-container">
                <div class="text">
                  Membresías anules
                  <div class="icon">
                    <InfoIcon
                      :messageId="8"
                      :isBlack="false"
                      @mouse-click="onQuestionClick"
                      @mouse-over="onQuestionMouseOver"
                      @mouse-leave="onQuestionMouseLeave"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__section2__item border-dotted">
              <div class="main3pv-container__container-profit-month__container1__section2__item__title">Amateur:</div>
              <div class="main3pv-container__container-profit-month__container1__section2__item__value">
                $ 0
                <span class="main3pv-container__container-profit-month__container1__section2__item__value__text"
                  >MXN</span
                >
              </div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__section2__item border-dotted">
              <div class="main3pv-container__container-profit-month__container1__section2__item__title">Ídolo:</div>
              <div class="main3pv-container__container-profit-month__container1__section2__item__value">
                ${{ periodCommissions.amount | toCurrencyString }}
                <span class="main3pv-container__container-profit-month__container1__section2__item__value__text"
                  >MXN</span
                >
              </div>
            </div>
            <div class="main3pv-container__container-profit-month__container1__section2__item">
              <div class="main3pv-container__container-profit-month__container1__section2__item__title">Leyenda:</div>
              <div class="main3pv-container__container-profit-month__container1__section2__item__value">
                ${{ periodCommissions.amount | toCurrencyString }}
                <span class="main3pv-container__container-profit-month__container1__section2__item__value__text"
                  >MXN</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="main3pv-container__container-profit-month__title2">
          <div class="text-with-question-container">
            <div class="text">
              Paquetes extras
              <div class="icon">
                <InfoIcon
                  :messageId="9"
                  :isBlack="false"
                  @mouse-click="onQuestionClick"
                  @mouse-over="onQuestionMouseOver"
                  @mouse-leave="onQuestionMouseLeave"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="main3pv-container__container-profit-month__container2">
          <div class="main3pv-container__container-profit-month__container2__item border-dotted-2">
            <div class="main3pv-container__container-profit-month__container2__item__title">Paquete 1</div>
            <div class="main3pv-container__container-profit-month__container2__item__title">
              2
            </div>
            <div class="main3pv-container__container-profit-month__container2__item__Value">
              $ 20
              <span class="main3pv-container__container-profit-month__container2__item__Value__text">MXN</span>
            </div>
          </div>
          <div class="main3pv-container__container-profit-month__container2__item border-dotted-2">
            <div class="main3pv-container__container-profit-month__container2__item__title">Paquete 2</div>
            <div class="main3pv-container__container-profit-month__container2__item__title">
              2
            </div>
            <div class="main3pv-container__container-profit-month__container2__item__Value">
              $ 20
              <span class="main3pv-container__container-profit-month__container2__item__Value__text">MXN</span>
            </div>
          </div>
          <div class="main3pv-container__container-profit-month__container2__item">
            <div class="main3pv-container__container-profit-month__container2__item__title">Paquete 3</div>
            <div class="main3pv-container__container-profit-month__container2__item__title">
              2
            </div>
            <div class="main3pv-container__container-profit-month__container2__item__Value">
              $ 20
              <span class="main3pv-container__container-profit-month__container2__item__Value__text">MXN</span>
            </div>
          </div>
        </div>
      </div>
      <!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL -->
      <!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL -->
      <!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL --><!-- TABLA GENERAL -->
      <!-- footer -->
      <div class="main3pv-footer-container">
        <div class="main3pv-footer-container__container">
          <div class="main3pv-footer-container__container__row1">
            <img class="login3pv-menu__img-footer" src="/images/escudo.png" />
          </div>
          <div class="main3pv-footer-container__container__row2">
            © Copyright – GolStats S.A.P.I. de C.V.
          </div>
        </div>
      </div>
      <!-- footer -->
    </div>
    <!-- container -->

    <InfoTooltip
      :show="showInfoTooltip"
      :coordinates="infoTooltipCoordinates"
      :text="infoTooltipText"
      @overlay-click="showInfoTooltip = false"
    />
    <Confirm
      question="¿Estás seguro que deseas cerrar tu sesión?"
      :showModalConfirm="showModalConfirm"
      @onConfirm="logout"
      @onCancel="closeModalConfirm"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { getAmountsMembershipsToRenew, getCatalogAmounts, infoMessages } from '@/utils/3pv';
import Confirm from '@/components/Modals/Confirm';
import MembershipUsers from '@/components/Sections/3pv/MembershipUsers';
import MembershipTable from '@/components/Sections/3pv/MembershipTable';
import InfoTooltip from '@/components/Sections/3pv/InfoTooltip';
import InfoIcon from '@/components/Sections/3pv/InfoIcon';
import { getMonthAbbreviationByIndex, subtractMonths } from '@/utils/date-and-time';
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';

const variablesToAdd = {
  monthly: [
    { name: 'Usuarios nuevos mensuales', variable_id: 8 },
    { name: 'Renovaciones mensuales', variable_id: 10 },
    { name: 'Reactivaciones mensuales', variable_id: 16 },
  ],
  yearly: [
    { name: 'Usuarios nuevos anuales', variable_id: 9 },
    { name: 'Renovaciones anuales', variable_id: 11 },
    { name: 'Reactivaciones anuales', variable_id: 17 },
    { name: 'Usuarios activos anuales anteriores', variable_id: 19 },
  ],
  monthlyTotal: [{ name: 'Usuarios nuevos mensuales', variable_id: 8 }],
  yearlyTotal: [{ name: 'Usuarios nuevos anuales', variable_id: 9 }],
};

export default {
  name: 'Main3pv',
  components: {
    Confirm,
    MembershipUsers,
    MembershipTable,
    VueApexCharts,
    InfoTooltip,
    InfoIcon,
  },
  data() {
    return {
      mOpen: false,
      showModalConfirm: false,
      sections: [
        {
          id: 1,
          name: 'membresías activas',
          component: 'MembershipTable',
        },
        {
          id: 2,
          name: 'total',
          component: 'MembershipUsers',
        },
        {
          id: 3,
          name: 'mes en curso',
          component: 'MembershipUsers',
        },
      ],
      selectedTab: {},
      infoData: {},
      yearlyChartData: this.getEmptyChartData('yearly', true),
      yearlyTotalChartData: this.getEmptyChartData('yearly'),
      monthlyChartData: this.getEmptyChartData('monthly'),
      chartKey: 0,
      lastWidthUpdated: null,
      showInfoTooltip: false,
      infoTooltipCoordinates: { x: 0, y: 0 },
      infoTooltipText: '',
    };
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapState(['displayWidth']),
    abrevName() {
      const arrayS = this.user.name.split(' ');
      var abrev = '';
      if (arrayS[0]) {
        abrev = abrev + arrayS[0].charAt(0);
      }
      if (arrayS[1]) {
        abrev = abrev + arrayS[1].charAt(0);
      }
      return abrev;
    },
    panelComponent() {
      return this.selectedTab.component || '';
    },
    paidCommissions() {
      if (this.infoData && this.infoData.commissions) {
        return this.infoData.commissions.paid_commissions || 0;
      }
      return 0;
    },
    periodCommissions() {
      if (this.infoData && this.infoData.commissions && this.infoData.commissions.this_period_commissions) {
        return {
          monthly: this.infoData.commissions.this_period_commissions.new_monthly_commissions,
          yearly: this.infoData.commissions.this_period_commissions.new_annual_commissions,
          applies: this.infoData.commissions.this_period_commissions.qualified_membership,
          amount: this.infoData.commissions.this_period_commissions.renewal_commissions,
        };
      }
      return {
        monthly: 0,
        yearly: 0,
        applies: 0,
        amount: 0,
      };
    },
    magicNumber() {
      if (this.infoData && this.infoData.commissions && this.infoData.commissions.magic_number) {
        return {
          nextGoal: this.infoData.commissions.magic_number.next_goal,
          actualPeriods: this.infoData.commissions.magic_number.actual_periods,
          totalPeriods: this.infoData.commissions.magic_number.total_periods,
          potentialAmount: this.infoData.commissions.magic_number.potential_amount,
        };
      }
      return {
        nextGoal: 0,
        actualPeriods: 0,
        totalPeriods: 0,
        potentialAmount: 0,
      };
    },
    chartData() {
      if (this.infoData) {
        if ([1].includes(this.selectedTab.id) && this.infoData.catalogs) {
          return this.yearlyChartData;
        } else if ([2].includes(this.selectedTab.id) && this.infoData.catalogs) {
          return this.yearlyTotalChartData;
        } else if (this.selectedTab.id === 3 && this.infoData.aggregate_daily_detail_commissions) {
          return this.monthlyChartData;
        }
      }
      return this.getEmptyChartData();
    },
  },
  watch: {
    infoData: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.catalogs) {
          this.fillYearlyChartData(newValue);
          this.fillYearlyTotalChartData(newValue);
          this.fillMonthlyChartData(newValue);
        }
      },
    },
    displayWidth: {
      immediate: true,
      handler(newValue) {
        if (this.lastWidthUpdated === null) {
          this.lastWidthUpdated = newValue;
        }
        this.compareWidth(newValue, this.chartData);
      },
    },
  },
  methods: {
    ...mapMutations(['SET_MENU_DRAWER', 'setIsSectionLoading']),
    ...mapActions('loginGeneral', ['doLogout']),
    fillYearlyTotalChartData(infoData) {
      const reversedCatalogs = [...infoData.catalogs];
      reversedCatalogs.reverse();
      reversedCatalogs.forEach((catalog, index) => {
        const catalogEndDate = new Date(catalog.end_date);
        if (index === 0) {
          this.yearlyTotalChartData.options.xaxis.categories.push(
            getMonthAbbreviationByIndex(subtractMonths(catalogEndDate, 1).getMonth()),
          );
        }
        this.yearlyTotalChartData.options.xaxis.categories.push(getMonthAbbreviationByIndex(catalogEndDate.getMonth()));
        const amounts = this.getCatalogAmountsTotal(catalog, {
          monthly: 0,
          yearly: 0,
        });
        this.yearlyTotalChartData.series[0].data.push(amounts.monthly);
        this.yearlyTotalChartData.series[1].data.push(amounts.yearly);
      });
      this.compareWidth(this.displayWidth, this.yearlyChartData);
    },
    fillYearlyChartData(infoData) {
      const reversedCatalogs = [...infoData.catalogs];
      reversedCatalogs.reverse();
      reversedCatalogs.forEach((catalog, index) => {
        const catalogEndDate = new Date(catalog.end_date);
        if (index === 0) {
          this.yearlyChartData.options.xaxis.categories.push(
            getMonthAbbreviationByIndex(subtractMonths(catalogEndDate, 1).getMonth()),
          );
        }
        this.yearlyChartData.options.xaxis.categories.push(getMonthAbbreviationByIndex(catalogEndDate.getMonth()));
        const amounts = this.getCatalogAmounts(catalog, {
          monthly: 0,
          yearly: 0,
        });
        this.yearlyChartData.series[0].data.push(amounts.monthly);
        this.yearlyChartData.series[1].data.push(amounts.yearly);
      });
      this.yearlyChartData.series[2].data = this.getActiveTotals(infoData.catalogs);
      this.compareWidth(this.displayWidth, this.yearlyChartData);
    },
    fillMonthlyChartData(infoData) {
      const dailyCommissions = [...infoData.aggregate_daily_detail_commissions];
      if (Array.isArray(dailyCommissions)) {
        dailyCommissions.sort((a, b) => {
          if (a.date > b.date) {
            return 1;
          }
          if (a.date < b.date) {
            return -1;
          }
          return 0;
        });
        this.monthlyChartData.options.xaxis.categories = ['  '];
        this.monthlyChartData.series[0].data = [0];
        this.monthlyChartData.series[1].data = [0];
        dailyCommissions.forEach(commission => {
          this.monthlyChartData.options.xaxis.categories.push(
            commission.date
              .split('T')[0]
              .split('-')
              .pop(),
          );
          this.monthlyChartData.series[0].data.push(commission.monthly);
          this.monthlyChartData.series[1].data.push(commission.annual);
        });
      }
      this.compareWidth(this.displayWidth, this.monthlyChartData, true);
    },
    getActiveTotals(catalogs) {
      const activeTotals = catalogs.reduce((accumulator, catalog, index, srcArray) => {
        const membershipsToRenew = {
          monthly: getAmountsMembershipsToRenew(catalog, srcArray[index + 1], 0, 'monthly'),
          yearly: getAmountsMembershipsToRenew(catalog, srcArray[index + 12], 0, 'yearly'),
        };
        const catalogAmounts = {
          monthly: getCatalogAmounts(catalog, 0, 'monthly'),
          yearly: getCatalogAmounts(catalog, 0, 'yearly'),
        };
        accumulator.push(
          membershipsToRenew.monthly + membershipsToRenew.yearly + catalogAmounts.monthly + catalogAmounts.yearly,
        );
        return accumulator;
      }, []);
      // add zero because it's the start month with no data
      activeTotals.push(0);
      return activeTotals.reverse();
    },
    openMenu() {
      this.mOpen = !this.mOpen;
    },
    onTabClicked(tabSection) {
      this.selectedTab = tabSection;
    },
    onClickLogout() {
      this.showModalConfirm = true;
      this.mOpen = false;
    },
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    async logout() {
      this.setIsSectionLoading(true);
      try {
        await this.doLogout({ is3pvLogout: true });
        window.location.replace('/login3pv');
      } finally {
        this.setIsSectionLoading(false);
      }
    },
    orderCatalogsByEndDate(catalogs) {
      if (catalogs) {
        catalogs.sort((a, b) => {
          if (a.end_date < b.end_date) {
            return 1;
          }
          if (a.end_date > b.end_date) {
            return -1;
          }
          return 0;
        });
      }
    },
    async fetchData() {
      try {
        // prod url https://3fkfa06mcg.execute-api.us-west-2.amazonaws.com/prod/3pv/sales/
        // QA url https://j3mdgjvv7l.execute-api.us-west-2.amazonaws.com/qa/3pv/sales/
        const response = await axios.get(
          `https://3fkfa06mcg.execute-api.us-west-2.amazonaws.com/prod/3pv/sales/${this.user.id}`,
        );
        return response.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    async initData() {
      try {
        const data = await this.fetchData();
        if (data) {
          this.orderCatalogsByEndDate(data.catalogs);
          this.infoData = data;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getCatalogAmounts(catalog, amounts) {
      if (!catalog) return amounts;
      for (const variable of catalog.variables) {
        const monthlyAdd = variablesToAdd.monthly.find(
          variableToAdd => variableToAdd.variable_id === variable.variable_id,
        );
        if (monthlyAdd) {
          amounts.monthly += variable.amount;
          continue;
        }
        const yearlyAdd = variablesToAdd.yearly.find(
          variableToAdd => variableToAdd.variable_id === variable.variable_id,
        );
        if (yearlyAdd) {
          amounts.yearly += variable.amount;
        }
      }

      return amounts;
    },
    getCatalogAmountsTotal(catalog, amounts) {
      if (!catalog) return amounts;
      for (const variable of catalog.variables) {
        const monthlyAdd = variablesToAdd.monthlyTotal.find(
          variableToAdd => variableToAdd.variable_id === variable.variable_id,
        );
        if (monthlyAdd) {
          amounts.monthly += variable.amount;
          continue;
        }
        const yearlyAdd = variablesToAdd.yearlyTotal.find(
          variableToAdd => variableToAdd.variable_id === variable.variable_id,
        );
        if (yearlyAdd) {
          amounts.yearly += variable.amount;
        }
      }

      return amounts;
    },
    getEmptyChartData(id = '', isActiveRequired = false) {
      const colors = ['#12a6f1', '#6eb90f'];
      const series = [
        {
          name: 'Mensuales',
          data: [0],
        },
        {
          name: 'Anuales',
          data: [0],
        },
      ];
      if (isActiveRequired) {
        colors.push('#4e62e8');
        series.push({
          name: 'Activas',
          data: [0],
        });
      }
      return {
        options: {
          chart: {
            id: id,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          toolbar: {
            show: false,
          },
          xaxis: {
            categories: [],
          },
          legend: {
            horizontalAlign: 'center',
            offsetX: 40,
          },
          colors,
          markers: {
            size: 6,
            strokeColors: '#fff',
            strokeWidth: 2,
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
          },
          stroke: {
            show: true,
            lineCap: 'butt',
            width: 2,
            dashArray: 2,
          },
          grid: {
            show: true,
            // borderColor: '#90A4AE',
            yaxis: {
              lines: {
                show: true,
              },
            },
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            shared: false,
          },
        },
        series,
      };
    },
    compareWidth(width, chartData, forced = false) {
      if (width < 642) {
        if (this.lastWidthUpdated >= 642 || forced) {
          chartData.options.xaxis.tickAmount = 5;
          this.chartKey++;
          this.lastWidthUpdated = width;
        }
      } else {
        if (this.lastWidthUpdated < 642 || forced) {
          chartData.options.xaxis.tickAmount = 'dataPoints';
          this.chartKey++;
          this.lastWidthUpdated = width;
        }
      }
    },
    onQuestionMouseOver($event) {
      this.infoTooltipText = infoMessages[$event.messageId];
      this.infoTooltipCoordinates = {
        x: $event.$event.pageX + 16,
        y: $event.$event.pageY,
      };
      this.showInfoTooltip = true;
    },
    onQuestionMouseLeave() {
      this.showInfoTooltip = false;
    },
    onQuestionClick($event) {
      this.infoTooltipText = infoMessages[$event.messageId];
      this.showInfoTooltip = true;
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    this.selectedTab = this.sections[0];
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
  background-color: #f7f8fa;
  background-image: unset;
}
#main-container {
  background-color: #f7f8fa;
}
#app {
  background-color: unset;
  background-image: unset;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
.title-dotted {
  @media screen and (max-width: 500px) {
    line-height: 18px !important;
    padding-top: 9px;
  }
  @media screen and (max-width: 400px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 320px) {
    line-height: 15px !important;
    padding-top: 12px;
    font-size: 14px !important;
  }
}
.border-dotted {
  border-bottom: 1px dotted #767676;
}
.border-dotted-2 {
  @media screen and (max-width: 500px) {
    border-bottom: 1px dotted #767676;
  }
}
.main3pv-footer-container {
  width: 100%;
  height: 110px;
  margin-top: 56px;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  @media screen and (max-width: 300px) {
    position: relative;
    margin-top: 85%;
  }
  &__container {
    height: 110px;
    width: 100%;

    &__row1 {
      height: 80px;
    }
    &__row2 {
      height: 30px;
      line-height: 30px;
      font-size: 15px;
      font-weight: normal;
      font-family: 'Avenir-Regular';
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #c6c8d4;
    }
  }
}
.main3pv-menu {
  z-index: 15;
  min-height: 54px;
  position: relative;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 35px;
  position: relative;
  height: 60px;
  margin-top: 20px;
  color: white;
  &__cont-menu {
    display: none;
    width: 192px;
    height: 60px;
    padding: 12px 31px 12px 19px;
    border-radius: 18px;
    position: absolute;
    top: 54px;
    right: 15%;
    box-shadow: 1px 19px 46px 0 rgba(102, 123, 138, 0.29);
    background-color: #fff;
    &__item {
      width: 158px;
      height: 42px;
      display: flex;
      justify-content: space-between;
      &__icon {
        margin-top: 8px;
        margin-left: 4px;
      }
      &__text {
        font-family: 'Avenir-Medium';
        font-size: 17px;
        width: 115px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 38px;
        letter-spacing: -0.05px;
        color: #222;
        float: left;
        text-align: left;
      }
    }
    & > :hover {
      background: #f7f7f7;
      cursor: pointer;
    }
  }
  &__cont-menu.show {
    display: inline-block;
  }
  &__img {
    width: 140px;
  }
  &__cont-item-user {
    width: 200px;
    height: 54px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 400px) {
      justify-content: flex-end;
    }
    &__name-user {
      font-family: 'Avenir-Demi';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.68px;
      text-align: right;
      color: #333232;
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    &__circle-user {
      width: 40px;
      height: 40px;
      margin: 0 0 0 16px;
      line-height: 40px;
      border-radius: 20px;
      background-color: #444048;
      font-family: 'Avenir-Demi';
      font-size: 13.3px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.69px;
      text-align: center;
      color: #f7f7f7;
      @media screen and (max-width: 400px) {
        margin-right: 15%;
      }
    }
    &__circle-user:hover {
      cursor: pointer;
    }
  }
}
.main3pv-container {
  width: 780px;
  height: 800px;
  display: inline-block;
  @media screen and (max-width: 780px) {
    width: 640px;
  }
  @media screen and (max-width: 640px) {
    width: 500px;
  }
  @media screen and (max-width: 500px) {
    width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 320px;
  }
  @media screen and (max-width: 320px) {
    width: 280px;
  }
  &__title {
    width: 100%;
    height: 45px;
    font-family: 'Avenir-Regular';
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 45px;
    margin-top: 5px;
    letter-spacing: normal;
    text-align: center;
    color: #4f4f4f;
    &__bold {
      font-family: 'Avenir-Pro-Bold';
    }
    @media screen and (max-width: 780px) {
      font-size: 27px;
    }
    @media screen and (max-width: 640px) {
      font-size: 24px;
    }
    @media screen and (max-width: 500px) {
      font-size: 21px;
    }
    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
  &__panel {
    width: 100%;
    //height: 178px;
    //padding: 0 0 15px;
    border-radius: 20px;
    margin-top: 22px;
    box-shadow: 0 2px 4px 0 rgba(137, 153, 169, 0.28), 0 0 7px 0 rgba(182, 200, 214, 0.5);
    background-color: #fff;
    @media screen and (max-width: 640px) {
      margin-top: 14px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 10px;
    }
    @media screen and (max-width: 400px) {
      margin-top: 5px;
    }
    @media screen and (max-width: 320px) {
      margin-top: 5px;
    }

    &__tabs {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-image: linear-gradient(to bottom, #fff 19%, #f8f8f8);
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      & > div {
        position: relative;
        height: 100%;
        font-family: Avenir-Demi, sans-serif;
        padding: 0.8em 0.2em 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        & > .spacer {
          position: absolute;
          left: 0;
          top: 50%;

          & > div {
            border-left: dotted 1px #979797;
            height: 1.1em;
            position: relative;
            top: -0.6em;
          }
        }
      }

      & .main3pv-container__panel__tabs__tab__title {
        text-transform: uppercase;
        font-size: 0.8rem;
        margin-bottom: 0.6em;

        @media screen and (max-width: 400px) {
          font-size: 0.6rem;
        }
      }

      & .indicator {
        width: 0;
        border-top: solid 3px #6eb90f;
        opacity: 0;
        transition: all 0.3s;
      }

      & .selected-tab {
        font-family: Avenir-Pro-Bold, sans-serif;
        font-weight: bold;
      }

      & .selected {
        width: 7.5rem;
        opacity: 1;

        @media screen and (max-width: 400px) {
          width: 80%;
        }
      }
    }
    &__content {
      padding: 1rem 0;
    }
  }
  &__info-date-stats {
    width: 100%;
    height: 44px;
    text-align: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #d8d8d8;
    margin-top: 14px;
    font-size: 13px;
    font-weight: bold;
    font-family: 'Avenir-Medium';
    font-stretch: normal;
    font-style: normal;
    line-height: 44px;
    letter-spacing: 0.68px;
    text-align: center;
    color: #7b7b7b;
    &__bold {
      font-family: 'Avenir-Pro-Bold';
      color: #4f4f4f;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
    @media screen and (max-width: 320px) {
      font-size: 9px;
    }
  }
  &__container-graph {
    margin-top: 1.5rem;
    width: 100%;
    height: 400px;
    background: #f3f4f6;
    display: flex;
    justify-content: center;
    align-items: center;

    &__inner {
      height: 90%;
      width: 90%;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }
  &__container-amount {
    width: 100%;
    height: 60px;
    margin-top: 18px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    &__item-amount {
      width: 30%;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 60px;
      &__container {
        width: 180px;
        height: 60px;
        &__title {
          width: 100%;
          height: 25px;
          text-align: center;
          line-height: 25px;
          font-family: 'Avenir-Demi';
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.63px;
          text-align: center;
          color: #818181;
        }
        &__money {
          width: 100%;
          height: 35px;
          text-align: center;
          line-height: 35px;
          font-family: 'Avenir-Medium';
          font-size: 21px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4f4f4f;
          &__bold {
            font-family: 'Avenir-Pro-Bold';
            font-size: 26px;
          }
        }
      }
    }
    &__item-amount.end {
      justify-content: flex-end;
    }
    &__item-amount.start {
      justify-content: flex-start;
    }
    &__item-amount.center {
      justify-content: center;
    }
    &__sep-item-amount {
      width: 1px;
      height: 10px;
      &__sep {
        width: 1px;
        height: 20px;
        background: #727272;
      }
    }
  }
  &__container-total-until-today {
    width: 100%;
    height: 100px;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 12px;
    margin-top: 40px;
    border: solid 2px #6eb90f;
    &__title {
      height: 44px;
      width: 100%;
      text-align: center;
      line-height: 44px;
      font-family: 'Avenir-Demi';
      letter-spacing: 0.2px;
      color: #383838;
      font-size: 15px;
      @media screen and (max-width: 640px) {
        font-size: 14px;
      }
      @media screen and (max-width: 400px) {
        font-size: 12px;
      }
      @media screen and (max-width: 320px) {
        font-size: 10px;
      }
    }
    &__total {
      height: 56px;
      width: 100%;
      background-color: #6eb90f;
      font-family: 'Avenir-Pro-Bold';
      line-height: 56px;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: white;
      text-align: center;
      @media screen and (max-width: 640px) {
        font-size: 32px;
      }
      @media screen and (max-width: 400px) {
        font-size: 24px;
      }
      @media screen and (max-width: 320px) {
        font-size: 20px;
      }
      &__text {
        font-family: 'Avenir-Pro-Medium';
        font-size: 26px;
        font-weight: 500;
        @media screen and (max-width: 400px) {
          font-size: 18px;
        }
        @media screen and (max-width: 320px) {
          font-size: 16px;
        }
      }
    }
  }
  &__container-profit-month {
    height: 450px;
    width: 100%;
    @media screen and (max-width: 500px) {
      height: 660px;
    }
    border-radius: 12px;
    border: solid 2px #414141;
    overflow: hidden;
    margin-top: 16px;
    &__title {
      height: 40px;
      width: 100%;
      text-align: center;
      line-height: 40px;
      font-family: 'Avenir-Demi';
      color: #383838;
      font-size: 17px;
      @media screen and (max-width: 320px) {
        font-size: 15px;
      }
    }
    &__title2 {
      height: 40px;
      width: 100%;
      text-align: center;
      line-height: 40px;
      background: #414141;
      font-family: 'Avenir-Pro-Medium';
      color: white;
      font-size: 16px;
      @media screen and (max-width: 640px) {
        font-size: 12px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
      @media screen and (max-width: 320px) {
        font-size: 13px;
      }
    }
    &__container1 {
      width: 100%;
      height: 208px;
      @media screen and (max-width: 500px) {
        height: 414px;
      }
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 388px 1px 388px;
      grid-template-columns: 388px 1px 388px;
      @media screen and (max-width: 780px) {
        -ms-grid-columns: 318px 1px 318px;
        grid-template-columns: 318px 1px 318px;
      }
      @media screen and (max-width: 640px) {
        -ms-grid-columns: 248px 1px 248px;
        grid-template-columns: 248px 1px 248px;
      }
      @media screen and (max-width: 500px) {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
      }
      -ms-grid-rows: -webkit-min-content auto;
      -ms-grid-rows: min-content auto;
      grid-template-rows: -webkit-min-content auto;
      grid-template-rows: min-content auto;
      &__section1 {
        width: 100%;
        height: 208px;
        // background: red;
        &__title {
          width: 100%;
          height: 40px;
          background-color: #414141;
          font-family: 'Avenir-Pro-Medium';
          color: white;
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.27px;
          text-align: center;
          line-height: 40px;
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
          @media screen and (max-width: 500px) {
            font-size: 16px;
          }
          @media screen and (max-width: 320px) {
            font-size: 13px;
          }
        }
        &__item {
          width: 100%;
          height: 56px;
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: 40% 60%;
          grid-template-columns: 40% 60%;
          &__title {
            width: 100%;
            height: 56px;
            text-align: right;
            font-family: 'Avenir-Demi';
            line-height: 56px;
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            color: #6d6d6d;
            @media screen and (max-width: 640px) {
              font-size: 13px;
            }
            @media screen and (max-width: 500px) {
              font-size: 17px;
            }
            @media screen and (max-width: 320px) {
              font-size: 14px;
            }
          }
          &__value {
            width: 100%;
            height: 56px;
            text-align: center;
            font-family: 'Avenir-Pro-Bold';
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 56px;
            letter-spacing: normal;
            text-align: center;
            color: #4f4f4f;
            &__text {
              font-family: 'Avenir-Pro-Medium';
              font-size: 21px;
              @media screen and (max-width: 640px) {
                font-size: 15px;
              }
              @media screen and (max-width: 500px) {
                font-size: 21px;
              }
              @media screen and (max-width: 320px) {
                font-size: 14px;
              }
            }
            @media screen and (max-width: 640px) {
              font-size: 18px;
            }
            @media screen and (max-width: 500px) {
              font-size: 24px;
            }
            @media screen and (max-width: 320px) {
              font-size: 19px;
            }
          }
        }
      }
      &__sep {
        width: 100%;
        height: 152px;
        &__border1 {
          background: #414141;
          height: 40px;
          width: 1px;
          float: left;

          &__sep {
            background: #414141;
            height: 14px;
            margin-top: 13px;
            border: 1px dotted white;
          }
        }
        &__border2 {
          background: white;
          height: 168px;
          width: 1px;
          float: left;
          border: 1px dotted #767676;
        }
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      &__section2 {
        width: 100%;
        height: 208px;
        // background: yellow;
        &__title {
          width: 100%;
          height: 40px;
          background-color: #414141;
          font-family: 'Avenir-Pro-Medium';
          color: white;
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.27px;
          text-align: center;
          line-height: 40px;
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
          @media screen and (max-width: 500px) {
            font-size: 16px;
          }
          @media screen and (max-width: 320px) {
            font-size: 13px;
          }
        }
        &__item {
          width: 100%;
          height: 56px;
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: 40% 60%;
          grid-template-columns: 40% 60%;
          &__title {
            width: 100%;
            height: 56px;
            text-align: right;
            font-family: 'Avenir-Demi';
            font-size: 17px;
            font-weight: normal;
            line-height: 56px;
            font-stretch: normal;
            font-style: normal;
            color: #6d6d6d;
            @media screen and (max-width: 640px) {
              font-size: 13px;
            }
            @media screen and (max-width: 500px) {
              font-size: 17px;
            }
            @media screen and (max-width: 320px) {
              font-size: 14px;
            }
          }
          &__value {
            width: 100%;
            height: 56px;
            text-align: center;
            font-family: 'Avenir-Pro-Bold';
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 56px;
            letter-spacing: normal;
            text-align: center;
            color: #4f4f4f;
            &__text {
              font-family: 'Avenir-Pro-Medium';
              font-size: 21px;
              @media screen and (max-width: 640px) {
                font-size: 15px;
              }
              @media screen and (max-width: 500px) {
                font-size: 21px;
              }
              @media screen and (max-width: 320px) {
                font-size: 14px;
              }
            }
            @media screen and (max-width: 640px) {
              font-size: 18px;
            }
            @media screen and (max-width: 500px) {
              font-size: 24px;
            }
            @media screen and (max-width: 320px) {
              font-size: 19px;
            }
          }
        }
      }
    }
    &__container2 {
      width: 100%;
      height: 168px;
      // background: rgb(144, 240, 144);
      @media screen and (max-width: 500px) {
        height: 122px;
      }
      // display: -ms-grid;
      display: inline-block;
      // -ms-grid-columns: 390px 390px;
      // grid-template-columns: 390px 390px;
      // @media screen and (max-width: 780px) {
      //   -ms-grid-columns: 319.5px 319.5px;
      //   grid-template-columns: 319.5px 319.5px;
      // }
      // @media screen and (max-width: 640px) {
      //   -ms-grid-columns: 249px 249px;
      //   grid-template-columns: 249px 249px;
      // }
      // @media screen and (max-width: 500px) {
      //   -ms-grid-columns: 1fr;
      //   grid-template-columns: 1fr;
      // }
      // -ms-grid-rows: -webkit-min-content auto;
      // -ms-grid-rows: min-content auto;
      // grid-template-rows: -webkit-min-content auto;
      // grid-template-rows: min-content auto;
      &__item {
        height: 54px;
        width: 100%;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: 500px) {
          -ms-grid-columns: 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
        }
        -ms-grid-rows: -webkit-min-content auto;
        -ms-grid-rows: min-content auto;
        grid-template-rows: -webkit-min-content auto;
        grid-template-rows: min-content auto;
        &__title {
          width: 100%;
          height: 54px;
          border: 1px dotted #767676;
          line-height: 54px;
          font-family: 'Avenir-Demi';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #6d6d6d;
          @media screen and (max-width: 640px) {
            font-size: 13px;
          }
          @media screen and (max-width: 500px) {
            border: 0px dotted #767676;
            text-align: center;
            font-size: 17px;
            // line-height: 54px !important;
          }
          @media screen and (max-width: 320px) {
            font-size: 14px;
          }
        }
        &__Value {
          width: 100%;
          height: 54px;
          border: 1px dotted #767676;
          line-height: 54px;
          font-family: 'Avenir-Pro-Bold';
          font-size: 24px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.94px;
          text-align: center;
          color: #4f4f4f;
          &__text {
            font-family: 'Avenir-Pro-Medium';
            font-size: 21px;
            @media screen and (max-width: 640px) {
              font-size: 15px;
            }
          }
          @media screen and (max-width: 640px) {
            font-size: 18px;
          }
          @media screen and (max-width: 500px) {
            border: 0px dotted #767676;
            line-height: 54px;
          }
        }
      }
    }
  }
}
.text-with-question-container {
  display: flex;
  width: 100%;
  justify-content: center;

  & > div.text {
    position: relative;
    width: fit-content;

    & > div.icon {
      position: absolute;
      right: -26px;
      top: 13px;
    }
  }
}
</style>

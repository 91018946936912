<template>
  <div v-if="displayWidth > 639" class="panel">
    <div v-for="(type, index) in reportType[this.id]" :key="index" class="panel__info">
      <div class="panel__info__data">
        <div
          v-for="dataType in type.dataTypes"
          :key="dataType.name"
          :style="getBorderStyle(type.type, dataType.type)"
          class="panel__info__data__type"
        >
          <template v-if="dataType.type == 1">
            <div class="panel__info__data__type__name">{{ dataType.name }}</div>
            <hr />
            <div class="panel__info__data__type__users">
              <div class="panel__info__data__type__users__icon">
                <img :src="`icons/icn_users_${dataType.type === 1 ? 'gray' : 'red'}.svg`" alt="users" />
              </div>
              <div class="panel__info__data__type__users__amount">
                {{ dataType.amount }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="panel__info__data__type__name">{{ dataType.name }}</div>
            <hr class="hr-last" />
            <div class="panel__info__data__type__users-2">
              <div class="panel__info__data__type__users-2__item-cancel-info">
                <div class="panel__info__data__type__users-2__item-cancel-info__conticons">
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__icon">
                    <img :src="`icons/users-icn-users-red.svg`" alt="users" />
                  </div>
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__amount">
                    {{ dataType.amount[1].amount }}
                  </div>
                </div>
                <div class="panel__info__data__type__users-2__item-cancel-info__title">
                  Intencionales
                </div>
              </div>
              <div class="panel__info__data__type__users-2__item-cancel-info">
                <div class="panel__info__data__type__users-2__item-cancel-info__conticons">
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__icon">
                    <img :src="`icons/icn_users_red.svg`" alt="users" />
                  </div>
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__amount">
                    {{ dataType.amount[0].amount }}
                  </div>
                </div>
                <div class="panel__info__data__type__users-2__item-cancel-info__title">
                  Por fallas
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="panel__info__name">membresías {{ type.name }}</div>
    </div>
  </div>
  <div v-else class="panel__mobile">
    <div class="panel__info__data">
      <div
        v-for="dataType in mobileInfoTypeSelected.dataTypes"
        :key="dataType.name"
        :style="getBorderStyle(mobileInfoTypeSelected.type, dataType.type)"
        class="panel__info__data__type"
      >
        <div class="panel__info__data__type__name">{{ dataType.name }}</div>
        <hr />
        <div class="panel__info__data__type__users">
          <template v-if="dataType.type == 1">
            <div class="panel__info__data__type__users__icon">
              <img :src="`icons/icn_users_${dataType.type === 1 ? 'gray' : 'red'}.svg`" alt="users" />
            </div>
            <div class="panel__info__data__type__users__amount">
              {{ dataType.amount }}
            </div>
          </template>
          <template v-else>
            <div class="panel__info__data__type__users-2">
              <div class="panel__info__data__type__users-2__item-cancel-info">
                <div class="panel__info__data__type__users-2__item-cancel-info__conticons">
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__icon">
                    <img :src="`icons/users-icn-users-red.svg`" alt="users" />
                  </div>
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__amount">
                    {{ dataType.amount[1].amount }}
                  </div>
                </div>
                <div class="panel__info__data__type__users-2__item-cancel-info__title">
                  Intencionales
                </div>
              </div>
              <div class="panel__info__data__type__users-2__item-cancel-info">
                <div class="panel__info__data__type__users-2__item-cancel-info__conticons">
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__icon">
                    <img :src="`icons/icn_users_red.svg`" alt="users" />
                  </div>
                  <div class="panel__info__data__type__users-2__item-cancel-info__conticons__amount">
                    {{ dataType.amount[0].amount }}
                  </div>
                </div>
                <div class="panel__info__data__type__users-2__item-cancel-info__title">
                  Por fallas
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="panel__info__name">membresías {{ mobileInfoTypeSelected.name }}</div>
    <div v-if="mobileInfoType === 1" class="panel__mobile__button right" @click="mobileInfoType = 2">
      <img class="rotated180" src="/icons/arrow-head.svg" />
    </div>
    <div v-else class="panel__mobile__button left" @click="mobileInfoType = 1">
      <img src="/icons/arrow-head.svg" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const purchasesAndCancellations = [
  { name: 'Usuarios nuevos mensuales', variable_id: 8, infoType: 1, dataType: 1 },
  { name: 'Cancelaciones automaticas mensuales', variable_id: 21, infoType: 1, dataType: 2 },
  { name: 'Cancelaciones intencionales mensuales', variable_id: 20, infoType: 1, dataType: 3 },

  { name: 'Usuarios nuevos anuales', variable_id: 9, infoType: 2, dataType: 1 },
  { name: 'Cancelaciones automaticas anuales', variable_id: 23, infoType: 2, dataType: 2 },
  { name: 'Cancelaciones intencionales anuales', variable_id: 22, infoType: 2, dataType: 3 },
];

export default {
  name: 'MembershipUsers',
  props: {
    id: {
      type: Number,
      default: 2,
    },
    catalogs: {
      type: Array,
      default: () => [],
    },
    dailyCommissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mobileInfoType: 1,
    };
  },
  watch: {
    id() {
      this.mobileInfoType = 1;
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    reportType() {
      const monthlyTotal = this.getEmptyMonthly();
      const yearlyTotal = this.getEmptyYearly();
      const monthlyCurrent = this.getEmptyMonthly();
      const yearlyCurrent = this.getEmptyYearly();
      const auxCurrent = this.getEmptyAux(monthlyCurrent, yearlyCurrent);
      const auxTotal = this.getEmptyAux(monthlyTotal, yearlyTotal);
      if (this.catalogs && this.catalogs.length) {
        // sum current amounts
        const lastCatalog = this.catalogs[0];
        this.addPurchasesAndCancellationsByCatalog(lastCatalog, auxCurrent, 1);
        // sum yearly amounts
        this.catalogs.forEach(catalog => {
          this.addPurchasesAndCancellationsByCatalog(catalog, auxTotal, 2);
        });
      }

      return {
        2: [monthlyTotal, yearlyTotal],
        3: [monthlyCurrent, yearlyCurrent],
      };
    },
    mobileInfoTypeSelected() {
      return this.reportType[this.id].find(infoType => infoType.type === this.mobileInfoType);
    },
  },
  methods: {
    getBorderStyle(infoTypeType, dataTypeType) {
      if (dataTypeType === 1) {
        if (infoTypeType === 1) {
          return { border: 'solid 3.9px rgba(35, 153, 225, 0.33)' };
        }
        return { border: 'solid 3.9px rgba(110, 185, 15, 0.33)' };
      }
      return { border: 'solid 3.9px white' };
    },
    addPurchasesAndCancellationsByCatalog(catalog, aux) {
      purchasesAndCancellations.forEach(variable => {
        const variableToSum = catalog.variables.find(
          catalogVariable => catalogVariable.variable_id === variable.variable_id,
        );
        if (variableToSum) {
          if (variable.dataType == 1) {
            aux[variable.infoType][variable.dataType].amount += variableToSum.amount;
          } else {
            if (variable.dataType == 2) {
              aux[variable.infoType][2].amount[0].amount += variableToSum.amount;
            } else {
              aux[variable.infoType][2].amount[1].amount += variableToSum.amount;
            }
          }
        }
      });
    },
    getEmptyMonthly() {
      return {
        name: 'mensuales',
        type: 1,
        dataTypes: [
          {
            name: 'compras',
            amount: 0,
            type: 1,
          },
          {
            name: 'cancelaciones',
            amount: [{ amount: 0 }, { amount: 0 }],
            type: 2,
          },
        ],
      };
    },
    getEmptyYearly() {
      return {
        name: 'anuales',
        type: 2,
        dataTypes: [
          {
            name: 'compras',
            amount: 0,
            type: 1,
          },
          {
            name: 'cancelaciones',
            amount: [{ amount: 0 }, { amount: 0 }],
            icon: 'icn_users_red.svg',
            type: 2,
          },
        ],
      };
    },
    getEmptyAux(monthly, yearly) {
      return {
        1: {
          1: monthly.dataTypes[0],
          2: monthly.dataTypes[1],
        },
        2: {
          1: yearly.dataTypes[0],
          2: yearly.dataTypes[1],
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.hr-last {
  width: 100% !important;
}
.panel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;

    &:first-child {
      justify-self: flex-end;
    }

    &__data {
      display: flex;

      &__type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 13.8px;
        width: 8.5rem;
        padding: 0.5rem 0 0;

        &__name {
          font-family: Avenir-Regular, sans-serif;
          font-size: 0.62rem;
          text-transform: uppercase;
        }

        &__users {
          width: 100%;
          display: flex;
          font-size: 1.6rem;
          font-family: Avenir-Pro-Bold, sans-serif;
          padding-left: 1.5rem;

          &__icon {
            margin-right: 0.4rem;
          }
        }

        &__users-2 {
          width: 100%;
          font-size: 1.6rem;
          display: flex;

          font-family: Avenir-Pro-Bold, sans-serif;
          &__item-cancel-info {
            width: 70px;
            &__title {
              font-size: 10px;
              width: 70px;
              line-height: 14px;
              float: left;
              font-family: 'Avenir-Demi';
              color: #222;
              text-align: center;
            }
            &__conticons {
              width: 70px;
              float: left;
              &__icon {
                width: 50%;
                float: left;
                img {
                  float: left;
                  margin-left: 4px;
                }
              }
              &__amount {
                width: 50%;
                height: 30px;
                line-height: 34px;
                text-align: center;
                float: left;
                font-size: 1.2rem;
              }
            }
          }
        }

        & hr {
          width: 55%;
          margin: 0.3rem 0;
          border-top: dashed 1px #b8bed6;
        }

        @media screen and (max-width: 640px) {
          &__users-2 {
            width: 100%;
            font-size: 1.6rem;
            margin-left: -1.5rem;
            width: fit-content;
            display: flex;
            font-family: Avenir-Pro-Bold, sans-serif;
            &__item-cancel-info {
              width: 70px;
              &__title {
                font-size: 10px;
                width: 70px;
                line-height: 14px;
                float: left;
                font-family: 'Avenir-Demi';
                color: #222;
                text-align: center;
              }
              &__conticons {
                width: 70px;
                float: left;
                &__icon {
                  width: 50%;
                  float: left;
                  img {
                    float: left;
                    margin-left: 4px;
                  }
                }
                &__amount {
                  width: 50%;
                  height: 30px;
                  line-height: 34px;
                  text-align: center;
                  float: left;
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
        @media screen and (max-width: 400px) {
          width: 6rem;

          &__users {
            width: fit-content;
            display: flex;
            font-size: 1.4rem;
            padding-left: 0;

            &__icon {
              margin-right: 0;
              width: 26px;
            }

            &__amount {
              font-size: 1.2rem;
            }
          }
          &__users-2 {
            width: 100%;
            font-size: 1.6rem;
            margin-left: 1px;
            width: fit-content;
            display: flex;
            font-family: Avenir-Pro-Bold, sans-serif;
            &__item-cancel-info {
              width: 40px;
              &__title {
                font-size: 6px;
                width: 40px;
                line-height: 14px;
                float: left;
                font-family: 'Avenir-Demi';
                color: #222;
                text-align: center;
              }
              &__conticons {
                width: 40px;
                float: left;
                &__icon {
                  width: 50%;
                  float: left;
                  img {
                    float: left;
                    margin-left: 4px;
                  }
                }
                &__amount {
                  width: 50%;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  float: left;
                  font-size: 1.2rem;
                }
              }
            }
          }
          // &__users-2 {
          //   width: fit-content;
          //   display: flex;
          //   font-size: 1.4rem;
          //   padding-left: 0;

          //   &__icon {
          //     margin-right: 0;
          //     width: 26px;
          //   }

          //   &__amount {
          //     font-size: 1.2rem;
          //   }
          // }
        }
      }
    }

    &__name {
      margin-top: 0.8rem;
      text-transform: uppercase;
      font-size: 0.68rem;
    }
  }

  &__mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__button {
      height: 40px;
      width: 40px;
      position: absolute;
      border-radius: 8px;
      background-color: #d8d8d8;
      top: 1.4rem;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img.rotated180 {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &.right {
        right: 10px;
      }

      &.left {
        left: 10px;
      }

      @media screen and (max-width: 400px) {
        height: 64px;
        width: 30px;
        top: 0.5rem;
      }
    }
  }
}
</style>

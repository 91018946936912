<template>
  <div class="question-icon">
    <img ref="questionIcon" :src="`icons/icn-${isBlack ? 'negra' : 'blanca-02'}.svg`" alt="info" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InfoIcon',
  props: {
    messageId: {
      type: Number,
      default: 0,
    },
    isBlack: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
  },
  watch: {
    displayWidth(newValue) {
      this.setListeners(newValue);
    },
  },
  methods: {
    setListeners(displayWidth) {
      if (displayWidth > 768) {
        this.setMouseOverListener();
      } else {
        this.setClickListener();
      }
    },
    setMouseOverListener() {
      this.$refs.questionIcon.removeEventListener('click', this.onClick);
      this.$refs.questionIcon.addEventListener('mouseover', this.onMouseOver);
      this.$refs.questionIcon.addEventListener('mouseleave', this.onMouseLeave);
    },
    setClickListener() {
      this.$refs.questionIcon.removeEventListener('mouseover', this.onMouseOver);
      this.$refs.questionIcon.removeEventListener('mouseleave', this.onMouseLeave);
      this.$refs.questionIcon.addEventListener('click', this.onClick);
    },
    onMouseOver($event) {
      this.$emit('mouse-over', { $event, messageId: this.messageId });
    },
    onMouseLeave() {
      this.$emit('mouse-leave');
    },
    onClick($event) {
      this.$emit('mouse-click', { $event, messageId: this.messageId });
    },
  },
  mounted() {
    this.setListeners(this.displayWidth);
  },
};
</script>

<style scoped>
.question-icon {
  width: 20px;
  cursor: pointer;
}
</style>

<template>
  <div v-if="show && isMobile" class="overlay" @click="$emit('overlay-click')">
    <div class="text-container">
      {{ text }}
    </div>
  </div>
  <div v-else-if="show" class="text-container" :style="topLeft">
    <div>
      {{ text }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InfoTooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    coordinates: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
        };
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    isMobile() {
      return this.displayWidth < 769;
    },
    topLeft() {
      return { top: `${this.coordinates.y}px`, left: `${this.getX()}px` };
    },
  },
  methods: {
    getX() {
      if (this.coordinates.x + 20 + 288 > this.displayWidth) {
        return this.coordinates.x - 288 - 30;
      }
      return this.coordinates.x;
    },
  },
};
</script>

<style scoped lang="scss">
.text-container {
  position: fixed;
  width: 288px;
  z-index: 8;
  opacity: 1 !important;
  border-radius: 12px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.43);
  background-color: #252525;
  color: white;
  font-size: 0.8rem;
  font-family: Avenir-Medium, sans-serif;
  padding: 1rem 2.5rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
</style>

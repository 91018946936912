export const infoMessages = {
  1: 'En este renglón se contabilizan las membresías activas al cierre del  mes anterior.',
  2: 'En este renglón se contabilizan las membresías nuevas del plan “Amateur”.',
  3: 'En este renglón se contabilizan las membresías nuevas del plan “Ídolo”.',
  4: 'En este renglón se contabilizan las membresías nuevas del plan “Leyenda”.',
  5:
    'En este renglón se indican las membresías que siguen activas (es decir, el usuario no ha cancelado' +
    ' manualmente), pero todavía no ha llegado la fecha de renovación de este mes (NOTA: es importante ' +
    'recordar que la fecha de renovación de cada usuario es diferente, y depende de la fecha de compra ' +
    'original de cada usuario).',
  6:
    'En este renglón se contabilizan todas las membresías activas, incluyendo nuevas compras y renovaciones ' +
    '(independientemente si ya se renovó en este mes o no, mientras el usuario no haya cancelado su membresía ' +
    'manualmente o se haya cancelado por falta de pago).',
  7:
    'Es la cantidad económica a recibir por membresías nuevas y renovaciones mensuales en el mes dependiendo ' +
    'el plan al que pertenecen. ',
  8:
    'Es la cantidad económica a recibir por membresías nuevas y renovaciones anuales ' +
    'en el mes dependiendo el plan al que pertenecen.',
  9:
    'Representa la cantidad de paquetes extras  vendidos y cantidad económica a ' +
    'recibir dependiendo el tipo de paquetes en el mes en curso.',
  10:
    'El menú te permite filtrar entre:\n\n\n\n' +
    'Local/visitante: \n\n\n\nMuestra datos considerando únicamente los partidos en los que el equipo' +
    ' ha jugado en la misma condición en la que se va a dar el partido (como local o visitante)' +
    'en el torneo.\n\n\n\nTodos los partidos: \n \n \n \nMuestra ' +
    'datos considerando todos los partidos que el equipo ha jugado en el torneo.',
  11: 'En este renglón se contabilizan las membresías renovadas del plan “Amateur”.',
  12: 'En este renglón se contabilizan las membresías renovadas del plan “Ídolo”.',
  13: 'En este renglón se contabilizan las membresías renovadas del plan “Leyenda”.',
  14: 'En este renglón se contabilizan las membresías canceladas en el transcurso del mes.',
};

const membershipsToRenewThisMonthVariablesToAdd = {
  monthlyAdd: [
    //last
    { name: 'Usuarios nuevos mensuales', variable_id: 8 },
    { name: 'Renovaciones mensuales', variable_id: 10 },
    { name: 'Reactivaciones mensuales', variable_id: 16 },
  ],
  monthlyRest: [
    //current
    { name: 'Renovaciones mensuales', variable_id: 10 },
    { name: 'Cancelaciones mensuales', variable_id: 13 },
  ],
  yearlyAdd: [
    //last -13
    { name: 'Usuarios nuevos anuales', variable_id: 9 },
    { name: 'Renovaciones anuales', variable_id: 11 },
    { name: 'Reactivaciones anuales', variable_id: 17 },
  ],
  yearlyRest: [
    //current
    { name: 'Renovaciones anuales', variable_id: 11 },
    { name: 'ancelaciones anuales', variable_id: 14 },
  ],
};

const variablesToAdd = {
  monthly: [
    { name: 'Usuarios nuevos mensuales', variable_id: 8 },
    { name: 'Renovaciones mensuales', variable_id: 10 },
    { name: 'Reactivaciones mensuales', variable_id: 16 },
  ],
  yearly: [
    { name: 'Usuarios nuevos anuales', variable_id: 9 },
    { name: 'Renovaciones anuales', variable_id: 11 },
    { name: 'Reactivaciones anuales', variable_id: 17 },
    { name: 'Usuarios activos anuales anteriores', variable_id: 19 },
  ],
};

export function getAmountsMembershipsToRenew(catalog, catalogLast, Amount = 0, type) {
  if (!catalog) return Amount;
  let AmountLastAux = 0;
  let AmountCurrentAux = 0;
  let AmountAux = 0;
  for (const variable of catalog.variables) {
    if (type === 'monthly') {
      const monthlyAdd = membershipsToRenewThisMonthVariablesToAdd.monthlyRest.find(
        variableToAdd => variableToAdd.variable_id === variable.variable_id,
      );
      if (monthlyAdd) {
        AmountCurrentAux += variable.amount;
      }
    } else {
      const yearlyRest = membershipsToRenewThisMonthVariablesToAdd.yearlyRest.find(
        variableToAdd => variableToAdd.variable_id === variable.variable_id,
      );
      if (yearlyRest) {
        AmountCurrentAux += variable.amount;
      }
    }
  }
  if (catalogLast) {
    for (const variable of catalogLast.variables) {
      if (type === 'monthly') {
        const monthlyAdd = membershipsToRenewThisMonthVariablesToAdd.monthlyAdd.find(
          variableToAdd => variableToAdd.variable_id === variable.variable_id,
        );
        if (monthlyAdd) {
          AmountLastAux += variable.amount;
        }
      } else {
        const yearlyRest = membershipsToRenewThisMonthVariablesToAdd.yearlyAdd.find(
          variableToAdd => variableToAdd.variable_id === variable.variable_id,
        );
        if (yearlyRest) {
          AmountLastAux += variable.amount;
        }
      }
    }
  } else {
    AmountLastAux = 0;
  }

  AmountAux = AmountLastAux - AmountCurrentAux;
  return AmountAux;
}

export function getCatalogAmounts(catalog, amount = 0, type = 'monthly') {
  if (!catalog) return amount;
  let amountAux = amount;
  for (const variable of catalog.variables) {
    const monthlyAdd = variablesToAdd[type].find(variableToAdd => variableToAdd.variable_id === variable.variable_id);
    if (monthlyAdd) {
      amountAux += variable.amount;
    }
  }

  return amountAux;
}
